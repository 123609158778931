
const Home = () => import('@/pages/Home');
const About = () => import('@/pages/About');
const Gallery = () => import('@/pages/Gallery');


const routes = [
    {
        path: '/',
        name: 'Gallery',
        component: Gallery,
        props: true
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/gallery/:gal',
        name: 'Gallery',
        component: Gallery,
        props: true
    }
    // {
    //     path: '/gallery/:gal/:img',
    //     name: 'Gallery',
    //     component: Gallery,
    //     props: true
    // }
];

export default routes;
