import Vue from 'vue'
import App from './App.vue'
import './custom.scss'
import VueRouter from 'vue-router';
import BootstrapVue from 'bootstrap-vue'
import routes from './routes';
import VueMasonry from 'vue-masonry-css'
import {fetch as fetchPolyfill} from 'whatwg-fetch'

Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(VueMasonry);
Vue.config.productionTip = false
// Configure router
const router = new VueRouter({
  routes,
  linkActiveClass: 'active',
  // mode: 'history'
});

new Vue({
  render: h => h(App),
    router
}).$mount('#app')
