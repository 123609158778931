<template>
  <div id="app" :class="{'menu-open': menuOpen}">
    <!-- <div class="badge-container">
      <div v-for="badge in markers" :key="badge.name">
        <Badge :name="badge.name" :image="badge.image" />
      </div>
    </div> -->

  <div id="content-wrap">
    <nav :class="{'open': menuOpen}" class="">
        <ul class="nav">
          <li>
            <router-link :class="{'select': currPage == 'About'}" to="/about">
              About
            </router-link>
          </li>
          <li v-for="c in categories">
            <router-link :class="{'select': currPage == c.slug}"  :to="'/gallery/'+c.slug">
              <span v-html="$options.filters.Upper(c.name)"></span>
            </router-link>
          </li>
        </ul>
        <div :class="{'open': menuOpen}" id="burger-menu" v-on:click="toggleOpen">
          <span></span>
          <span></span>
          <span></span>
        </div> 
    </nav>
  
    <h1 class="header" :class="{'open': menuOpen}">Poets & Nomads</h1>
  <!-- <div class="wp-content" v-html="posts[0].post_content"></div> -->
  
    <!-- <Map v-if="markers.length > 0" :markers="markers" /> -->

    <router-view />
    </div>
      
  </div>
</template>

<script>
import Badge from './components/Badge.vue'
import Map from './components/Map.vue'
import {BASE_URL} from './config.js';
export default {
  name: 'app',
  data(){
    return {
      categories: [],
      media: [],
      posts: [],
      bgID: 0,
      currPage: '',
      menuOpen: false
    }
  },
  components: {
    Badge,
    Map
  },
  mounted(){
    this.$root.isMobile = false; //initiate as false
    // device detection
    if (/mobile/i.test(navigator.userAgent) && !/ipad|tablet/i.test(navigator.userAgent)) {
      this.$root.isMobile = true;
    }
    // if (!this.$root.isMobile) {
    //   document.getElementsByTagName("BODY")[0].addEventListener('mousemove',this.onMouseMove)
    // }
    var that = this;
    this.$root.pageTitles = {"about": "About"}
    fetch(BASE_URL+'/wp-json/wp/v2/categories')
      .then((r) => r.json())
      .then(function(res) {
        // console.log(res)
        that.categories = res;
        that.categories.pop();
        that.categories.forEach(element => {
          that.$root.pageTitles[element.slug] = element.name;
        });
        console.log(that.$root.pageTitles)
        that.$root.categories = that.categories;
        that.$root.$emit('catsload')
      } );
    

     this.currPage = this.$route.name;
      
  },
  methods: {
    toggleOpen: function() {
      this.menuOpen = !this.menuOpen;

    },
    onMouseMove: function(e) {
      if (e.clientX < 100 && !this.menuOpen) {
        this.menuOpen = true;
      } else if (e.clientX > 400 && this.menuOpen) {
        this.menuOpen = false;
      }

    }
  },
  watch: {
    currPage: function(t) {

    },
    $route: function(t,f) {
      if(t.params.gal) {
        this.currPage = t.params.gal
      } else {
        this.currPage = t.name
      }
      this.$root.pageTitle = this.$root.pageTitles[this.currPage];
      if (this.menuOpen) {

        this.toggleOpen()
      }
      // if (this.menuOpen && this.$root.isMobile) {

      // }
      // console.log(t)
    }
  },
  filters: {
       // Filter definitions
        Upper(value) {
          var v = value.split(" and ");
          return v.join("<br>and<br> ");
        }
    }
}
</script>

<style lang="scss">
body {
  height: initial !important;
}
// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;
// Small devices

@mixin sm-up {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}
@mixin sm-dn {
  @media (max-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

h1 {
  width: 100%;
  text-align: center;
  margin-bottom: 1em !important;
  &.header {
    transition: all .4s;
    left: 0%;
    // transform: translateX(-50%);
    max-width: 100%;
    position: fixed;
    z-index: 3;
    background: #FFF;
    padding: 10px 0;
    margin-top: 0 !important;
    top: 0px;
    &.open {
      left: calc( 20vw);
          max-width: calc(100% - 20vw);
      }
    
          @include sm-dn {
                font-size: 1.5rem;
                    margin-top: 11px !important;
                    &.open {

            left: 100%;
                    }
          }
  }
}
.wp-content {
  text-align: center;
}
.badge-container {
  padding-top: 60px;
  display: flex;
  justify-content: space-between;
}
#content {
  max-width: 100%;
  margin-right: initial;
  margin-left: 0vw;
  transition: all .4s;
  .menu-open & {
    margin-left: 20vw;
  }
}
.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}
.background .bg-slide-container {
  transition: width .5s, background-size 7s;
  width: 0%;
  overflow: hidden;
  height: 900px;
  position: absolute;
  background-attachment: fixed;
  background-size: 120%;
  // transition: background-size 7s;
  background-position: center;
  z-index: 2;
} 
.background .bg-slide-container.open {
  
  z-index: 1;
  background-size: 100%;
  width: 100%;
  height: 100%;
}
.background .bg-slide-container img {
  width: 100%;
}
html, body {
    margin: 0;
    height: 100%;
    background-color: #FAFAFA;
    /* font-family: 'Roboto', sans-serif; */
    font-family: 'Bebas Neue', cursive;
  width: 100vw;
  overflow-x: hidden;
}
body {
  margin: 0;
  overflow-y: scroll;

}
h1 {
  font-family: 'Bebas Neue', cursive;
  margin-top: .5em !important;
  margin-bottom: .5em !important;
  text-shadow: 2px 2px 10px  #FFFFFFCC;
}
iframe {
  box-shadow: 0px 0px 15px #000000AA;
}
nav {
  transition: margin-top .3s;
  margin-top: -9vw;
  position: relative;
  padding-bottom: 45px;
  background: #00000066;
  padding-top: 1vw;
  &.open {
    margin-top: 0px;
  }
  .nav {
    list-style: none;
    margin: auto;
    text-align: center;
    padding-left: 0;
    display: block;

}
.nav li {
  display: inline-block;
  width: 20%;
  font-family: 'Bebas Neue', cursive;
  font-size: 2vw;

}
.nav li   a:hover {
  
  color: #999;

}
.nav li   a {
    transition: color .5s;

    
    color: #000;
    text-decoration: none;
    line-height: 2.5vw;
    display: block;
    &.select {
      color: #AAA;
    }
  }
}

body {
  margin-left: 5%;
  margin-right: 5%;
}
#content-wrap {
  z-index: 2;
}
#app > h1 {
  text-align: center;
  color: 212121;
  font-family: 'Bebas Neue', cursive;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

#footer{
  text-align: center;
  text-decoration: none;
  color: 212121;
}
#burger-menu {
  width: 40px;
  height: 40px;
  position: absolute;
  margin-top: 20px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 1;
      right: 50%;
    bottom: -.2vw;
  // display: none;
  @media (max-width: 980px) {
    // display: block;
  }
  span {
    display: block;
    position: absolute;
    height: .25vw;
    width: 100%;
    background-color: #000;
    // border-bottom: 2px solid #4e4e50;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;

    &:nth-child(1) {
      top: .5vw;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
    &:nth-child(2) {
      top: 1.1vw;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
    &:nth-child(3) {
      top: 1.7vw;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
  }
  &.open {
    span {
      background-color: #000;
      &:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 0px;
        left: 6px;
      }
      &:nth-child(2) {
        width: 0%;
        opacity: 0;
      }
      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 28px;
        left: 6px;
      }
    }
  }
}
@include sm-dn {
  iframe {
    width: 100%;
    height: auto;
    background: #000;
  }
  #content-wrap {
    margin-top: 4em;
  }
  
}

nav {
    transition: left .3s;
    height: 100vh;
    width: 20vw;
    position: fixed;
    margin: 0;
    top: 0;
    left: -100vw;
    z-index: 1000;
    background: #00000000;
    &.open {
      left: 0vw;
      margin-top: 0px;
    }
    .nav {
      position: absolute;
      top: 120px;
      
      left: 26px;
      li {
        display: block;
        margin: auto;
        width: 100%;
        margin-bottom: 1em;
        font-size: 1.5em;
        text-align: left;
            a {
              display: block;
              line-height: 1em;
            }
      }
    }
  }
  #burger-menu {
    position: fixed;
    right: inherit;
    left: 26px;
    bottom: inherit;
    top: 0px;
    transform: scale(.6);
    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background-color: #000;
      // border-bottom: 2px solid #4e4e50;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;

      &:nth-child(1) {
        top: 0px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }
      &:nth-child(2) {
        top: 7px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }
      &:nth-child(3) {
        top: 14px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }
    }
  }
@include sm-dn {
 nav {
   width: 100vw;
 }
 #content {
  max-width: 100%;
  margin-right: initial;
  margin-left: 0vw;
  transition: all .3s;
  .menu-open & {
    margin-left: 100vw;
  }
}
}
</style>
